// src/axiosInstance.js
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL + '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
})

export default axiosInstance
