import { openDB } from 'idb';

const dbPromise = openDB('offline-app-db', 1, {
  upgrade(db) {
    db.createObjectStore('data', {
      keyPath: 'id',
      autoIncrement: true,
    });
  },
});

export const saveData = async (data) => {
  const db = await dbPromise;
  await db.put('data', data);
};

export const getData = async () => {
  const db = await dbPromise;
  return await db.getAll('data');
};

export const clearData = async () => {
  const db = await dbPromise;
  await db.clear('data');
};
